.indicator {
  display: inline-flex;
  padding-right: 5px;
  height: 12px;
  width: 12px;
  background-color: #f44336;
  border-radius: 50%;
  &-active {
    background-color: #4caf50
  }
}
