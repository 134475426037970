body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a {
  text-decoration: none;
  color: #000;
}

.flex{
  display: flex;
}

.flex-center{
  display: flex;
  align-items: center;
}
.p5{
  padding: 5px;
}
.w100 {
  width: 100%;
}
.w95 {
  width: 95%;
}
.w90 {
  width: 90%;
}
.bc {
  background-color: #3f50b5;
}

/*.active > .MuiListItem-button{*/
/*  text-decoration: none;*/
/*  background-color: rgba(0, 0, 0, 0.04);*/
/*}*/

.danger > .MuiSnackbarContent-root{
  background: #f44336
}
.success > .MuiSnackbarContent-root{
  background: #4caf50
}
.success-text{
  color: #4caf50
}
.info > .MuiSnackbarContent-root{
  background: #2196f3
}
.warning > .MuiSnackbarContent-root{
  background: #ff9800
}

.loader-container {
  margin: 0 45%;
  display: block;
  left: 0;
}
.blur-text {
  color: #c3c2c2 !important;
}
.center{
  text-align: center !important;
}
.textarea {
  border: none;
  outline: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}
.textarea:focus{
  border: none;
  transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  border-bottom: 2px solid #3f50b5;
}
.MuiOutlinedInput-notchedOutline {
  border: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.23) !important;
  border-radius: 0 !important
}

.MuiAutocomplete-inputRoot {
  padding: 4px !important;
}


.crop-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 80px;
}

.controls {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 80px;
  display: flex;
  align-items: center;
  text-align: center;
}
.sliderContainer {
  /*display: grid;*/
}
.slider-item {
  min-width: 160px;
  max-width: 160px;
  /*position: absolute !important;*/
  /*right: 20px;*/
}